import React, {Fragment} from 'react';
import Sticky from 'react-stickynode';
import {Modal} from '@redq/reuse-modal';
import {ThemeProvider} from 'styled-components';
import {theme} from 'common/theme/agencyModern';
import {ResetCSS} from 'common/assets/css/style';
import {
    GlobalStyle,
    ContentWrapper,
} from 'containers/AgencyModern/agencyModern.style';
import {DrawerProvider} from 'common/contexts/DrawerContext';
import Navbar from 'containers/AgencyModern/Navbar';
import Banner from 'containers/AgencyModern/Banner';
import Services from 'containers/AgencyModern/Services';
import UltimateFeature from 'containers/AgencyModern/UltimateFeature';
import Seo from 'components/seo';
import {data} from 'common/data/AgencyModern/index';

const AgencyModern = () => {
    let lang = "uz";
    return (
        <ThemeProvider theme={theme}>
            <Fragment>
                <Seo title="AgencyModern"/>
                <Modal/>
                <ResetCSS/>
                <GlobalStyle/>
                <ContentWrapper>
                    <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                        <DrawerProvider>
                            <Navbar data={data[lang]}/>
                        </DrawerProvider>
                    </Sticky>
                    <Banner data={data[lang]}/>
                    <Services data={data[lang]}/>
                    <UltimateFeature data={data[lang]}/>
                </ContentWrapper>
            </Fragment>
        </ThemeProvider>
    );
};
export default AgencyModern;
